import { ScrollArea } from '@/components/ui/scroll-area';
import { useObservationStore } from '@/store/observationStore';
import { useSelectedItemStore } from '@/store/selectedItemStore';
import { useNavigate } from 'react-router-dom';
import ListEntry from '../list-entry';

export function ObservationTab() {
  const { observations } = useObservationStore();
  const { toggleItem, isItemSelected } = useSelectedItemStore();

  const navigate = useNavigate();

  if (observations.length === 0) {
    return (
      <div className="w-full text-center mt-6 text-sm text-muted-foreground">
        Your Observations tab is empty.
        <br />
        Observations that don’t appear in other tabs will be shown here.
        <br />
        To add or remove tabs click inbox settings.
      </div>
    );
  }

  return (
    <ScrollArea className="flex-grow">
      <ul className="divide-y">
        {observations.map((obs) => (
          <li
            key={obs.id}
            className="py-2 px-4 hover:bg-muted/40 transition-colors cursor-pointer"
            onClick={() => navigate(`/dashboard/observation/${obs.id}`)}
          >
            <ListEntry
              title={obs.title || `Observation #${obs.id}`}
              description={obs.description}
              date={new Date(obs.createdAt)}
              checked={isItemSelected({
                remoteId: obs.id,
                itemType: 'observation'
              })}
              onCheckedChange={() => toggleItem({ remoteId: obs.id, itemType: 'observation' })}
              hasAudio={obs.voices.length > 0}
              hasPhotos={obs.photos.length > 0}
            />
          </li>
        ))}
      </ul>
    </ScrollArea>
  );
}
