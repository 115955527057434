import { Button } from '@/components/ui/button';
import { Sheet, SheetContent, SheetDescription, SheetHeader, SheetTitle } from '@/components/ui/sheet';
import { ScrollArea } from '@/components/ui/scroll-area';
import { useObservationStore } from '@/store/observationStore';
import { useEffect, useState } from 'react';
import { Observation } from '@/types/observation';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select';
import { Inspection, useInspectionStore } from '@/store/inspectionStore';
import { Loader2 } from 'lucide-react';

function ObservationDetails({ observation }: { observation: Observation }) {
  return (
    <div className="p-4 border rounded-lg">
      <h3 className="font-semibold mb-2">{observation.title || `Observation #${observation.id}`}</h3>
      <p className="text-sm text-muted-foreground mb-4">{observation.description || 'No description'}</p>

      <div className="flex gap-2 text-sm text-muted-foreground">
        {observation.photos.length > 0 && (
          <span>
            {observation.photos.length} photo{observation.photos.length !== 1 ? 's' : ''}
          </span>
        )}
        {observation.voices.length > 0 && (
          <span>
            {observation.voices.length} recording{observation.voices.length !== 1 ? 's' : ''}
          </span>
        )}
      </div>
    </div>
  );
}

export function LinkObservationSheet() {
  const { isLinking, parentSiteId, setIsLinking, fetchObservations, observations } = useObservationStore();
  const { linkObservation, inspections, fetchInspections } = useInspectionStore();

  const [parentInspection, setParentInspection] = useState<Inspection>();
  useEffect(() => {
    if (!parentSiteId) {
      setIsLinking(false, undefined);
      return;
    }
    const inspection = inspections.find((i) => i.id === parentSiteId);
    if (inspection) setParentInspection(inspection);
    else setIsLinking(false, undefined);
  }, [parentSiteId, inspections]);

  const [selectedObservation, setSelectedObservation] = useState<string>('');

  const [isLinkingLoading, setIsLinkingLoading] = useState(false);
  const handleLinkObservation = async () => {
    if (!parentSiteId || !selectedObservation) return;
    setIsLinkingLoading(true);
    await linkObservation(parentSiteId, parseInt(selectedObservation));
    setIsLinkingLoading(false);
    setIsLinking(false, undefined);
    fetchInspections();
  };

  useEffect(() => {
    fetchObservations();
  }, []);

  return (
    <Sheet open={isLinking} onOpenChange={(open) => setIsLinking(open, parentSiteId)}>
      <SheetContent className="w-screen sm:w-[540px]">
        <SheetHeader>
          <SheetTitle>Link Existing Observation</SheetTitle>
          <SheetDescription>Select an observation to link to this inspection</SheetDescription>
        </SheetHeader>

        <div className="flex flex-col gap-4 py-4">
          <Select value={selectedObservation} onValueChange={setSelectedObservation}>
            <SelectTrigger>
              <SelectValue placeholder="Select an observation" />
            </SelectTrigger>
            <SelectContent>
              {observations.map((obs) => (
                <SelectItem
                  key={obs.id}
                  value={obs.id.toString()}
                  disabled={parentInspection?.observations.some((o) => o.id === obs.id)}
                >
                  {obs.title || `Observation #${obs.id}`}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>

          <ScrollArea className="flex-1 pr-4">
            {selectedObservation && (
              <ObservationDetails
                observation={observations.find((obs) => obs.id.toString() === selectedObservation)!}
              />
            )}
          </ScrollArea>
          <Button
            className="w-full"
            disabled={!selectedObservation || !parentSiteId || isLinkingLoading}
            onClick={handleLinkObservation}
          >
            {isLinkingLoading && <Loader2 className="w-4 h-4 mr-2 animate-spin" />}
            Link Observation
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
}
