import { create } from 'zustand';

export type ItemType = 'observation' | 'photo' | 'recording' | 'inspection' | 'project' | 'report';

export interface Item {
  remoteId: number;
  itemType: ItemType;
}

interface SelectedItem {
  selectedItems: Item[];
  selectItem: (item: Item) => void;
  unselectItem: (item: Item) => void;
  toggleItem: (item: Item) => void;
  clearSelectedItems: () => void;
  isItemSelected: (item: Item) => boolean;
}

export const useSelectedItemStore = create<SelectedItem>((set, get) => ({
  selectedItems: [],

  selectItem: (item: Item) =>
    set((state) => {
      if (!state.selectedItems.some((i) => i.remoteId === item.remoteId && i.itemType === item.itemType)) {
        return { selectedItems: [...state.selectedItems, item] };
      }
      return state;
    }),

  unselectItem: (item: Item) =>
    set((state) => {
      const newItems = state.selectedItems.filter(
        (i) => !(i.remoteId === item.remoteId && i.itemType === item.itemType)
      );
      return { selectedItems: newItems };
    }),

  toggleItem: (item: Item) => {
    set((state) => {
      const itemExists = state.selectedItems.some((i) => i.remoteId === item.remoteId && i.itemType === item.itemType);
      const newItems = itemExists
        ? state.selectedItems.filter((i) => !(i.remoteId === item.remoteId && i.itemType === item.itemType))
        : [...state.selectedItems, item];
      return { selectedItems: newItems };
    });
  },

  clearSelectedItems: () => set({ selectedItems: [] }),

  isItemSelected: (item: Item) => {
    return get().selectedItems.some((i) => i.remoteId === item.remoteId && i.itemType === item.itemType);
  }
}));
