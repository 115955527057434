import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useAuthStore } from './store/authStore';
import Login from './components/Login';
import { useEffect, useState } from 'react';
import DashboardLayout from '@/layouts/DashboardLayout';
import Dashboard from '@/pages/dashboard/Dashboard.tsx';
import HeaderLayout from '@/layouts/HeaderLayout.tsx';
import { SiteInspectionDetails } from './pages/dashboard/details/InspectionDetails';
import { ObservationDetails } from './pages/dashboard/details/ObservationDetails';
import { ProjectDetails } from './pages/dashboard/details/ProjectDetails';
import { PhotoDetails } from './pages/dashboard/details/PhotoDetails';
import { VoiceDetails } from './pages/dashboard/details/VoiceDetails';
import { UserType } from './lib/categories';
import DetailsLayout from './layouts/DetailsLayout';
import { ReportDetails } from './pages/dashboard/details/ReportDetails';

function ProtectedRoute({ children }: { children: React.ReactNode }) {
  const { user, loading } = useAuthStore();

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!user) {
    return <Navigate to="/login" replace />;
  }

  return <>{children}</>;
}

function App() {
  const { checkAuth, user } = useAuthStore();

  useEffect(() => {
    checkAuth();
  }, []);

  const [currentTab, setCurrentTab] = useState('projects');

  useEffect(() => {
    setCurrentTab(user?.user_type === UserType.MEDICAL ? 'patients' : 'projects');
  }, [user]);

  return (
    <Router>
      <Routes>
        <Route path="/login" element={<Login />} />

        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <HeaderLayout />
            </ProtectedRoute>
          }
        >
          <Route element={<DashboardLayout />}>
            <Route index element={<Dashboard currentTab={currentTab} setCurrentTab={setCurrentTab} />} />
            <Route element={<DetailsLayout />}>
              <Route path="photo/:id" element={<PhotoDetails />} />
              <Route path="voice/:id" element={<VoiceDetails />} />
              <Route path="inspection/:id" element={<SiteInspectionDetails />} />
              <Route path="project/:id" element={<ProjectDetails />} />
              <Route path="report/:id" element={<ReportDetails />} />
              <Route path="observation/:id" element={<ObservationDetails />} />
            </Route>
          </Route>
        </Route>

        {/* Root redirects */}
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="*" element={<Navigate to="/dashboard" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
