import { useCallback, useEffect, useState } from 'react';
import { useReportStore } from '@/store/reportStore.ts';
import { Navigate, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { DetailsOutletContext } from '@/layouts/DetailsLayout';
import { Button } from '@/components/ui/button';
import { RefreshCcw } from 'lucide-react';
import { cn } from '@/lib/utils';
import ListEntry from '@/components/explorer/list-entry';
import { Textarea } from '@/components/ui/textarea';
import { DashboardOutletContext } from '@/layouts/DashboardLayout';

export function ReportDetails() {
  const navigate = useNavigate();

  const { reports, regenerateReport, updateDescription, updateTitle } = useReportStore();
  const params = useParams();

  const { setScreen } = useOutletContext<DashboardOutletContext>();

  useEffect(() => {
    setScreen({ screen: 'project', id: Number(params.id) });
  }, [params.id, setScreen]);

  if (isNaN(Number(params.id))) return <Navigate to="/dashboard" />;

  const report = reports.find((r) => r.id === Number(params.id));

  if (!report) return <Navigate to="/dashboard" />;

  const { setParentTitleCallback, setParentTitle, setCreatedAt, setActionButtons } =
    useOutletContext<DetailsOutletContext>();

  const [isEditing, setIsEditing] = useState(false);
  const [description, setDescription] = useState(report.description || '');

  const updateTitleCallback = useCallback(
    (title: string) => {
      updateTitle(report.id, title);
    },
    [report.id]
  );

  useEffect(() => {
    setParentTitle(report.title || `Report #${report.id}`);
    setParentTitleCallback(() => updateTitleCallback);
    setDescription(report.description || '');
    setCreatedAt(new Date(report.createdAt));
  }, [report]);

  const [saveTimeout, setSaveTimeout] = useState<NodeJS.Timeout | null>(null);
  const debouncedSave = useCallback(
    (newDescription: string) => {
      if (saveTimeout) clearTimeout(saveTimeout);
      const timeout = setTimeout(() => {
        updateDescription(report.id, newDescription);
      }, 500);
      setSaveTimeout(timeout);
    },
    [report.id, updateDescription]
  );
  useEffect(() => {
    return () => {
      if (saveTimeout) clearTimeout(saveTimeout);
    };
  }, [saveTimeout]);

  const handleDescriptionChange = (newDescription: string) => {
    setDescription(newDescription);
    debouncedSave(newDescription);
  };

  const [isRegenerating, setIsRegenerating] = useState(false);
  const regenerate = async () => {
    setIsRegenerating(true);
    await regenerateReport();
    setIsRegenerating(false);
  };

  useEffect(() => {
    setActionButtons(
      <Button onClick={regenerate} variant="secondary" disabled={isRegenerating}>
        <RefreshCcw className={cn('w-4 h-4 mr-2', isRegenerating && 'animate-spin')} />
        Regenerate
      </Button>
    );

    return () => setActionButtons(null);
  }, [isRegenerating]);

  return (
    <div>
      <div className="flex flex-col gap-2">
        <h3 className="font-semibold">Description</h3>
        <div className="p-3" onClick={() => setIsEditing(true)}>
          {isEditing ? (
            <Textarea
              className="w-full h-full focus:outline-none"
              value={description}
              onChange={(e) => handleDescriptionChange(e.target.value)}
              onBlur={() => {
                setIsEditing(false);
                // TODO: Save changes to project store/backend
              }}
              autoFocus
            />
          ) : description ? (
            <p className="whitespace-pre-wrap">{description}</p>
          ) : (
            <p className="whitespace-pre-wrap text-muted-foreground">Click to add description</p>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <h3 className="font-semibold">Observations</h3>
        <div className="flex flex-col gap-3 mb-36 border rounded-lg">
          {report.observations.length === 0 ? (
            <p className="text-muted-foreground text-sm">No site inspections yet</p>
          ) : (
            <ul className="divide-y">
              {report.observations.map((observation) => (
                <li
                  key={observation.id}
                  onClick={() => navigate(`/dashboard/observation/${observation.id}`)}
                  className="py-2 px-4 hover:bg-muted/40 transition-colors cursor-pointer"
                >
                  <ListEntry
                    title={observation.title || `Observation #${observation.id}`}
                    description={observation.description}
                    date={new Date(observation.createdAt)}
                    checked={false}
                    onCheckedChange={() => {}}
                    hasAudio={observation.voices.length > 0}
                    hasPhotos={observation.photos.length > 0}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>

      <div className="flex flex-col gap-2">
        <h3 className="font-semibold">Site Inspections</h3>
        <div className="flex flex-col gap-3 mb-36 border rounded-lg">
          {report.inspections.length === 0 ? (
            <p className="text-muted-foreground text-sm">No site inspections yet</p>
          ) : (
            <ul className="divide-y">
              {report.inspections.map((inspection) => (
                <li
                  key={inspection.id}
                  onClick={() => navigate(`/dashboard/inspection/${inspection.id}`)}
                  className="py-2 px-4 hover:bg-muted/40 transition-colors cursor-pointer"
                >
                  <ListEntry
                    title={inspection.title || `Site Inspection #${inspection.id}`}
                    description={inspection.description}
                    date={new Date(inspection.createdAt)}
                    checked={false}
                    onCheckedChange={() => {}}
                    hasAudio={inspection.observations.some((obs) => obs.voices.length > 0)}
                    hasPhotos={inspection.observations.some((obs) => obs.photos.length > 0)}
                  />
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
}
