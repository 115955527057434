import { useObservationStore } from '@/store/observationStore';
import { ChevronLeftIcon, ChevronRightIcon, ClipboardPlus, RefreshCcw, RefreshCwIcon } from 'lucide-react';
import { Button } from '@/components/ui/button';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { useAuthStore } from '@/store/authStore.ts';
import { tabsConfig, UserType } from '@/lib/categories';
import { useMediaStore } from '@/store/mediaStore';
import { Checkbox } from '../ui/checkbox';
import { useSelectedItemStore } from '@/store/selectedItemStore.ts';
import { Skeleton } from '@/components/ui/skeleton.tsx';
import { cn } from '@/lib/utils.ts';
import { useInspectionStore } from '@/store/inspectionStore.ts';
import { useReportStore } from '@/store/reportStore';
import { useProjectStore } from '@/store/projectStore';
import { useNavigate } from 'react-router-dom';

export function ExplorerList({
  currentTab,
  setCurrentTab
}: {
  currentTab: string;
  setCurrentTab: (tab: string) => void;
}) {
  // fixme: hacky loading states fix this 🤢
  const { isLoading: obsLoading, error, retry, pagination, fetchObservations } = useObservationStore();
  const { isLoading: mediaLoading, fetchMedia } = useMediaStore();
  const { fetchProjects } = useProjectStore();
  const { fetchInspections } = useInspectionStore();
  const { fetchReports, generateReport } = useReportStore();
  const { user } = useAuthStore();
  const { selectedItems, clearSelectedItems } = useSelectedItemStore();
  const navigate = useNavigate();

  const handlePageChange = (direction: 'next' | 'prev') => {
    if (!pagination) return;
    const newPage = direction === 'next' ? pagination.page + 1 : pagination.page - 1;
    if (newPage < 1 || newPage > pagination.totalPages) return;
    fetchObservations(newPage);
  };

  const renderPaginationControls = () => {
    if (!pagination) return null;
    const start = Math.min((pagination.page - 1) * pagination.limit + 1, pagination.total);
    const end = Math.min(pagination.page * pagination.limit, pagination.total);
    return (
      <div className="flex items-center gap-4">
        <div className="text-sm text-muted-foreground">
          {obsLoading || mediaLoading ? <Skeleton className={'w-12 h-6'} /> : `${start}-${end} of ${pagination.total}`}
        </div>
        <div className="flex items-center gap-2">
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8"
            onClick={() => handlePageChange('prev')}
            disabled={pagination.page <= 1}
          >
            <ChevronLeftIcon className="h-4 w-4" />
          </Button>
          <Button
            variant="ghost"
            size="icon"
            className="h-8 w-8"
            onClick={() => handlePageChange('next')}
            disabled={pagination.page >= pagination.totalPages}
          >
            <ChevronRightIcon className="h-4 w-4" />
          </Button>
        </div>
      </div>
    );
  };

  const refresh = () => {
    fetchProjects();
    fetchObservations(1);
    fetchMedia();
    fetchInspections();
    fetchReports(1);
  };

  return (
    <div className="w-full">
      <div className="bg-stone-100 px-4 pt-4 pb-2">
        <div className="flex flex-col gap-2">
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-1">
              <Checkbox
                className="ml-2 mr-2"
                //  add `indeterminate` checked state. fix on click to full select
                checked={selectedItems.length > 0}
                onCheckedChange={() => clearSelectedItems()}
              />
              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                onClick={refresh}
                disabled={obsLoading || mediaLoading}
              >
                <RefreshCwIcon className={cn('h-4 w-4', obsLoading || mediaLoading ? 'animate-spin' : '')} />
              </Button>

              <Button
                variant="ghost"
                size="icon"
                className="h-8 w-8"
                disabled={
                  selectedItems.length === 0 || (currentTab !== 'site-inspection' && currentTab !== 'observations')
                }
                onClick={async () => {
                  const report = await generateReport();
                  if (report) navigate(`/dashboard/report/${report.id}`);
                }}
              >
                <ClipboardPlus className="h-4 w-4" />
              </Button>
              {selectedItems.length > 0 && (
                <p className={'text-muted-foreground text-sm italic'}>
                  {selectedItems.length}
                  <span className="hidden sm:inline"> selected</span>
                </p>
              )}
            </div>
            {renderPaginationControls()}
          </div>
        </div>
      </div>
      <Tabs
        value={currentTab}
        onValueChange={(value) => {
          clearSelectedItems();
          setCurrentTab(value);
        }}
      >
        <div className="bg-stone-50 pb-2 border-b">
          <TabsList className="border-b px-4 bg-stone-50 rounded-none border-none h-full">
            <div className="flex flex-wrap">
              {tabsConfig[user?.user_type || UserType.CONSTRUCTION].map((tab) => (
                <TabsTrigger key={tab.value} value={tab.value}>
                  {tab.label}
                </TabsTrigger>
              ))}
            </div>
          </TabsList>
        </div>
        {obsLoading || mediaLoading ? (
          <Skeleton className="m-4 flex h-8" />
        ) : error ? (
          <div className="flex flex-col items-center mt-4 gap-4 w-full">
            <p className="text-destructive">{error}</p>
            <Button variant="outline" size="sm" onClick={() => retry()} className="flex items-center gap-2">
              <RefreshCcw className="h-4 w-4" />
              Retry
            </Button>
          </div>
        ) : (
          tabsConfig[user?.user_type || UserType.CONSTRUCTION].map((tab) => (
            <TabsContent key={tab.label} value={tab.value} className="mt-0">
              <tab.tabView />
            </TabsContent>
          ))
        )}
      </Tabs>
    </div>
  );
}
