import { ScrollArea } from '@/components/ui/scroll-area';
import { useSelectedItemStore } from '@/store/selectedItemStore';
import { Project, useProjectStore } from '@/store/projectStore';
import { useNavigate } from 'react-router-dom';
import ListEntry from '../list-entry';

export function ProjectList({ projects }: { projects: Project[] }) {
  const navigate = useNavigate();
  const { toggleItem, isItemSelected } = useSelectedItemStore();
  return (
    <ScrollArea className="flex-grow">
      <ul className="divide-y">
        {projects.map((project) => (
          <li
            key={project.id}
            className="py-2 px-4 hover:bg-muted/40 transition-colors cursor-pointer"
            onClick={() => navigate(`/dashboard/project/${project.id}`)}
          >
            <ListEntry
              title={project.title || `Project #${project.id}`}
              description={project.description}
              date={new Date(project.createdAt)}
              checked={isItemSelected({
                remoteId: project.id,
                itemType: 'project'
              })}
              onCheckedChange={() => toggleItem({ remoteId: project.id, itemType: 'project' })}
              hasAudio={project.inspections.some((ins) => ins.observations.some((obs) => obs.voices.length > 0))}
              hasPhotos={project.inspections.some((ins) => ins.observations.some((obs) => obs.photos.length > 0))}
            />
          </li>
        ))}
      </ul>
    </ScrollArea>
  );
}

export function ProjectTab() {
  const { projects } = useProjectStore();
  if (projects.length === 0) {
    return (
      <div className="w-full text-center mt-6 text-sm text-muted-foreground">
        Your Projects tab is empty.
        <br />
        Create a new project to start organizing your site inspections.
      </div>
    );
  }
  return <ProjectList projects={projects} />;
}
