import { ExplorerList } from '@/components/explorer/ExplorerList.tsx';
import { useEffect } from 'react';
import { useAuthStore } from '@/store/authStore.ts';
import { useObservationStore } from '@/store/observationStore';
import { useNavigate, useOutletContext, Navigate } from 'react-router-dom';
import { useMediaStore } from '@/store/mediaStore';
import { useInspectionStore } from '@/store/inspectionStore';
import { useProjectStore } from '@/store/projectStore';
import { DashboardOutletContext } from '@/layouts/DashboardLayout';
import { useReportStore } from '@/store/reportStore';

export default function Dashboard({
  currentTab,
  setCurrentTab
}: {
  currentTab: string;
  setCurrentTab: (tab: string) => void;
}) {
  const { setScreen } = useOutletContext<DashboardOutletContext>();

  useEffect(() => {
    setScreen(null);
  }, [setScreen]);

  const navigate = useNavigate();

  const { fetchObservations } = useObservationStore();
  const { fetchMedia } = useMediaStore();
  const { fetchProjects } = useProjectStore();
  const { fetchInspections } = useInspectionStore();
  const { fetchReports } = useReportStore();
  const { user } = useAuthStore();

  if (!user) return <Navigate to="/login" />;

  // TODO: fixme just hacking this altogether
  useEffect(() => {
    fetchObservations(1);
  }, [navigate, fetchObservations]);

  useEffect(() => {
    fetchMedia(1);
  }, [navigate, fetchMedia]);

  useEffect(() => {
    fetchInspections();
  }, [navigate, fetchInspections]);

  useEffect(() => {
    fetchProjects();
  }, [navigate, fetchProjects]);

  useEffect(() => {
    fetchReports(1);
  }, [navigate, fetchReports]);

  return <ExplorerList currentTab={currentTab} setCurrentTab={setCurrentTab} />;
}
