import { api } from './authStore';
import { toast } from 'sonner';
import { Observation } from '@/types/observation';
import { usePhotoStore } from './photoStore';
import { useRecordingStore } from './recordingStore';
import { Inspection, useInspectionStore } from '@/store/inspectionStore.ts';
import { Project, useProjectStore } from '@/store/projectStore.ts';
import { createPersistentStore } from '@/lib/utils';
import { useMediaStore } from './mediaStore';

// FIXME: move this out of here
interface PaginationState {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

interface CreateObservationResponse {
  projects: Project[];
  inspection: Inspection;
  observation: Observation;
}

interface ObservationState {
  observations: Observation[];
  // FIXME: make this algebraic
  isCreating: boolean;
  isLinking: boolean;

  parentSiteId: number | undefined;
  parentProjectId: number | undefined;
  // FIXME: no good
  searchQuery: string;
  isLoading: boolean;
  error: string | null;
  pagination: PaginationState | null;
  searchDebounceTimeout: NodeJS.Timeout | null;

  setIsCreating: (isCreating: boolean, parentSiteId?: number, parentProjectId?: number) => void;
  setIsLinking: (isLinking: boolean, parentSiteId?: number) => void;

  setSearchQuery: (query: string) => void;

  updateTitle: (observationId: number, title: string) => Promise<void>;
  updateDescription: (observationId: number, description: string) => Promise<void>;

  fetchObservations: (page?: number, search?: string) => Promise<void>;
  deleteObservation: (observationId: number) => Promise<void>;
  generateSummary: (observationId: number) => Promise<void>;
  createObservation: () => Promise<CreateObservationResponse>;
  retry: () => Promise<void>;
}

export const useObservationStore = createPersistentStore<ObservationState>('observationData', (set, get) => ({
  observations: [],
  isCreating: false,
  isLinking: false,
  // from explorer, which site to create the observation in TODO: improve data type
  parentSiteId: undefined,
  parentProjectId: undefined,
  searchQuery: '',
  isLoading: false,
  error: null,
  pagination: null,
  searchDebounceTimeout: null,

  setIsCreating: (isCreating, parentSiteId?, parentProjectId?) =>
    set({ isCreating, parentProjectId, parentSiteId, isLinking: false }),

  // if parent is undefined linking must be closed
  setIsLinking: (isLinking, parentSiteId?) => set({ isLinking, parentSiteId, isCreating: false }),

  setSearchQuery: (query) => {
    set({ searchQuery: query });
    const searchDebounceTimeout = get().searchDebounceTimeout;
    if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);
    const timeout = setTimeout(() => {
      get().fetchObservations(1, query);
    }, 300);
    set({ searchDebounceTimeout: timeout });
  },

  fetchObservations: async (page: number = 1, search?: string) => {
    set({ isLoading: true, error: null });
    try {
      const searchQuery = search ?? get().searchQuery;
      const response = await api.get(
        `/protected/observations/list?page=${page}&limit=20${
          searchQuery ? `&search=${encodeURIComponent(searchQuery)}` : ''
        }`
      );
      set({
        observations: response.data.observations || [],
        pagination: response.data.pagination,
        isLoading: false
      });
    } catch (error) {
      set({
        error: error instanceof Error ? error.message : 'An error occurred',
        isLoading: false
      });
    }
  },

  deleteObservation: async (observationId) => {
    try {
      const response = await api.delete(`/protected/observations/${observationId}`);
      if (response.status === 200) {
        set((state) => ({
          observations: state.observations.filter((obs) => obs.id !== observationId),
          selectedObs: null
        }));
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      toast(`Error deleting observation ${observationId}`);
    }
  },

  generateSummary: async (observationId) => {
    try {
      const response = await api.get(`/protected/observations/${observationId}/generate`);
      if (response.status === 200) {
        const { title, description } = response.data;
        set((state) => ({
          observations: state.observations.map((obs) =>
            obs.id === observationId ? { ...obs, title, description } : obs
          )
        }));
        // HACKY RE UPDATE INSPECTIONS
        const inspectionStore = useInspectionStore.getState();
        await inspectionStore.fetchInspections();
      }
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      toast(`Error generating report`);
    }
  },

  createObservation: async () => {
    try {
      const photoStore = usePhotoStore.getState();
      const photoIds = photoStore.currentPhotos
        .map((photo) => photo.remoteId)
        .filter((id): id is number => id !== null);
      const voiceStore = useRecordingStore.getState();
      const voiceIds = voiceStore.currentRecordings
        .map((recording) => recording.remoteId)
        .filter((id): id is number => id !== null);
      const response = await api.post(`/protected/observations/create`, {
        description: '',
        photoIds,
        voiceIds,
        parentSiteId: get().parentSiteId,
        parentProjectId: get().parentProjectId
      });
      console.log(response.data);
      // const data = response.data;
      set({ isCreating: false, parentSiteId: undefined, parentProjectId: undefined });
      voiceStore.currentRecordings = [];
      photoStore.currentPhotos = [];

      // FIXME
      const inspectionStore = useInspectionStore.getState();
      const projectStore = useProjectStore.getState();
      const mediaStore = useMediaStore.getState();
      await Promise.all([
        get().fetchObservations(),
        inspectionStore.fetchInspections(),
        projectStore.fetchProjects(),
        mediaStore.fetchMedia()
      ]);

      return response.data;
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      console.error(error);
      toast(`Error creating observation`);
    }
  },

  retry: async () => await get().fetchObservations(),

  updateTitle: async (observationId: number, title: string) => {
    try {
      await api.put(`/protected/observations/${observationId}/updateTitle`, { title });
      set((state) => ({
        observations: state.observations.map((obs) => (obs.id === observationId ? { ...obs, title } : obs))
      }));
    } catch (error) {
      toast(`Error updating observation title`);
    }
  },

  updateDescription: async (observationId: number, description: string) => {
    try {
      await api.put(`/protected/observations/${observationId}/updateDescription`, { description });
      set((state) => ({
        observations: state.observations.map((obs) => (obs.id === observationId ? { ...obs, description } : obs))
      }));
    } catch (error) {
      toast(`Error updating observation description`);
    }
  }
}));
