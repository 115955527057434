import { Link, useLocation } from 'react-router-dom';
import { cn } from '@/lib/utils';
import { Button } from './ui/button';

const routes = [
  {
    path: '/dashboard',
    label: 'Home',
  },
  // {
  //   path: '/dashboard/observations',
  //   label: 'Observations',
  //   userTypes: [UserType.MEDICAL, UserType.CONSTRUCTION]
  // },
  // {
  //   path: '/dashboard/projects',
  //   label: 'Projects',
  //   userTypes: [UserType.CONSTRUCTION]
  // },
  // {
  //   path: '/dashboard/site-inspections',
  //   label: 'Site Inspections',
  //   userTypes: [UserType.CONSTRUCTION]
  // },
  // {
  //   path: '/dashboard/reports',
  //   label: 'Reports',
  //   userTypes: [UserType.MEDICAL, UserType.CONSTRUCTION]
  // }
];

export function SidebarNav() {
  const location = useLocation();
  // const { user } = useAuthStore();

  // const filteredRoutes = routes.filter(route =>
  //   route.userTypes.includes(user?.user_type || UserType.CONSTRUCTION)
  // );

  return (
    <nav className="space-y-1 w-full">
      {routes.map((route) => (
        <Link key={route.path} to={route.path}>
          <Button
            variant="ghost"
            className={cn(
              'w-full justify-start',
              location.pathname === route.path && 'bg-muted'
            )}
          >
            {route.label}
          </Button>
        </Link>
      ))}
    </nav>
  );
}
