import { api } from './authStore';
import { toast } from 'sonner';
import { Photo, Voice } from '@/types/media';
import { createPersistentStore } from '@/lib/utils';

interface PaginationState {
  total: number;
  page: number;
  limit: number;
  totalPages: number;
}

interface MediaState {
  photos: Photo[];
  voices: Voice[];
  isLoading: boolean;
  error: string | null;
  pagination: PaginationState | null;
  searchQuery: string;
  searchDebounceTimeout: NodeJS.Timeout | null;

  setSearchQuery: (query: string) => void;
  fetchMedia: (page?: number, search?: string) => Promise<void>;

  regenerateVoiceTranscriptAndSummary: (voiceId: number) => Promise<void>;
  regeneratePhotoSummary: (photoId: number) => Promise<void>;
}

// this is just hacky to store media states, both photos and voices,
// use photoStore / recordingStore for local mutations instead - this needs overhauling
export const useMediaStore = createPersistentStore<MediaState>('mediaData', (set, get) => ({
  photos: [],
  voices: [],
  isLoading: false,
  error: null,
  pagination: null,
  searchQuery: '',
  searchDebounceTimeout: null,

  setSearchQuery: (query) => {
    set({ searchQuery: query });
    const searchDebounceTimeout = get().searchDebounceTimeout;
    if (searchDebounceTimeout) clearTimeout(searchDebounceTimeout);
    const timeout = setTimeout(() => {
      get().fetchMedia(1, query);
    }, 300);
    set({ searchDebounceTimeout: timeout });
  },

  fetchMedia: async (page: number = 1, search?: string) => {
    set({ isLoading: true, error: null });
    try {
      const searchQuery = search ?? get().searchQuery;
      const response = await api.get(
        `/protected/media/list?page=${page}&limit=20${searchQuery ? `&search=${encodeURIComponent(searchQuery)}` : ''}`
      );

      set({
        photos: response.data.photos,
        voices: response.data.voices,
        pagination: response.data.pagination,
        isLoading: false
      });
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'An error occurred fetching media';
      set({
        error: errorMessage,
        isLoading: false
      });
      toast.error(errorMessage);
    }
  },

  regenerateVoiceTranscriptAndSummary: async (voiceId: number) => {
    try {
      const response = await api.post(`/protected/voice/${voiceId}/regenerate`);
      // TODO: use a map
      set((state) => ({
        voices: state.voices.map((v) =>
          v.id === voiceId
            ? {
                ...v,
                transcript: response.data.transcript,
                processedTranscript: response.data.processedTranscript
              }
            : v
        )
      }));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      toast(`Error regenerating recording`);
    }
  },

  regeneratePhotoSummary: async (photoId: number) => {
    try {
      const response = await api.post(`/protected/photo/${photoId}/regenerateSummary`);
      // TODO: use a map
      set((state) => ({
        photos: state.photos.map((p) => (p.id === photoId ? { ...p, llm_summary: response.data.llm_summary } : p))
      }));
    } catch (error) {
      toast(`Error regenerating photo`);
    }
  }
}));
