import { MediaTab } from '@/components/explorer/tab/MediaTab';
import { ObservationTab } from '@/components/explorer/tab/ObservationTab';
import { SiteInspectionTab } from '@/components/explorer/tab/SiteInspectionTab.tsx';
import { ProjectTab } from '@/components/explorer/tab/ProjectTab.tsx';
import { ReportTab } from '@/components/explorer/tab/ReportTab.tsx';

export enum UserType {
  CONSTRUCTION = 'CONSTRUCTION',
  MEDICAL = 'MEDICAL'
}

export const tabsConfig: Record<UserType, { value: string; label: string; tabView: React.FC }[]> = {
  [UserType.CONSTRUCTION]: [
    { value: 'projects', label: 'Projects', tabView: ProjectTab },
    { value: 'reports', label: 'Reports', tabView: ReportTab },
    {
      value: 'site-inspection',
      label: 'Site',
      tabView: SiteInspectionTab
    },
    {
      value: 'observations',
      label: 'Observations',
      tabView: ObservationTab
    },
    { value: 'media', label: 'Media', tabView: MediaTab }
  ],
  [UserType.MEDICAL]: [
    { value: 'patients', label: 'Patients', tabView: ObservationTab },
    { value: 'letters', label: 'Letters', tabView: ObservationTab },
    { value: 'consults', label: 'Consults', tabView: ObservationTab },
    { value: 'observations', label: 'Observations', tabView: MediaTab },
    { value: 'media', label: 'Media', tabView: MediaTab }
  ]
};
