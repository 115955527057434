import { create } from 'zustand';
import { api } from './authStore';
import { toast } from 'sonner';
import { CurrentPhoto } from '@/types/observation';

interface PhotoState {
  currentPhotos: CurrentPhoto[];

  addPhotos: (photoFiles: FileList | null) => Promise<void>;
  removePhoto: (photoId: string) => void;
  uploadPhoto: (photoId: string, file: File) => Promise<void>;
  regenerateSummary: (remotePhotoId: number) => void;
}

export const usePhotoStore = create<PhotoState>((set, get) => ({
  currentPhotos: [],

  addPhotos: async (files) => {
    if (files) {
      const newPhotos = Array.from(files).map((file) => ({
        id: Date.now().toString(),
        file: file,
        llm_summary: null,
        url: URL.createObjectURL(file),
        remoteId: null,
        failed: false,
        uploaded_at: new Date()
      })) as CurrentPhoto[];
      set((state) => ({
        currentPhotos: [...state.currentPhotos, ...newPhotos]
      }));
      await Promise.all(newPhotos.map((photo) => get().uploadPhoto(photo.id, photo.file)));
    }
  },

  removePhoto: (photoId) =>
    set((state) => ({
      currentPhotos: state.currentPhotos.filter((p) => p.id !== photoId)
    })),

  uploadPhoto: async (photoId, file) => {
    try {
      const formData = new FormData();
      formData.append('photo', file);
      const response = await api.post(`/protected/photo/upload`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
        maxContentLength: 1000000000,
        maxBodyLength: 1000000000
      });

      set((state) => ({
        currentPhotos: state.currentPhotos.map((p) =>
          p.id === photoId
            ? {
                ...p,
                remoteId: response.data.photo_id,
                llm_summary: response.data.llm_summary,
                failed: false
              }
            : p
        )
      }));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      set((state) => ({
        currentPhotos: state.currentPhotos.map((p) => (p.id === photoId ? { ...p, failed: true } : p))
      }));
      toast(`Error uploading photo ${photoId}`);
    }
  },
  regenerateSummary: async (remotePhotoId) => {
    try {
      const response = await api.post(`/protected/photo/${remotePhotoId}/regenerateSummary`);
      set((state) => ({
        currentPhotos: state.currentPhotos.map((p) =>
          p.remoteId === remotePhotoId
            ? {
                ...p,
                llm_summary: response.data.llm_summary,
                failed: false
              }
            : p
        )
      }));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    } catch (error) {
      set((state) => ({
        currentPhotos: state.currentPhotos.map((p) => (p.remoteId === remotePhotoId ? { ...p, failed: true } : p))
      }));
      toast(`Error regenerating summary for photo ${remotePhotoId}`);
    }
  }
}));
